import React, { useMemo, useState, useEffect, useRef } from "react";
import ReactTable from "components/react-table/ReactTable";
import Notiflix from "notiflix";
import { useHistory } from "react-router-dom";
import { Block } from "notiflix";
import classnames from "classnames";
import { WebUrl, ApiKey } from "util/Constant";
import { Config, ConfigEnum } from "util/Config";
import { handleSetPageOptions } from "util/Utility.js";
import QuotationDao from "data/QuotationDao.js";
import WinLossButtons from "components/buttons/WinLossButtons";
import moment from "moment";

///<summary>
///Author: Sim
///</summary>
export default () => {
  const [quotationList, setQuotationList] = useState([]);
  const history = useHistory();

  let configInstance = Config.getInstance();
  var token = configInstance.getValue(ConfigEnum._TOKEN);
  const [selectedRow, setSelectedRow] = useState();
  const _tableRef = useRef();
  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const getQuotationList = async () => {
    Block.circle("#quotationsTable");

    let dao = new QuotationDao();
    await dao.getQuotationList(token).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];

        setQuotationList(data);
        handleSetPageOptions(_tableRef, response[ApiKey._API_TOTAL_KEY] ?? 0);
      } else {
        Notiflix.Report.failure(
          "Error",
          "Failed to load quotations. Please try again later"
        );
      }
    });

    Block.remove("#quotationsTable");
  };

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const deleteQuotation = async (row) => {
    var id = row.id;
    let dao = new QuotationDao();

    await dao.delete(id, token).then((responseJson) => {
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        Notiflix.Report.Success("Success", "Quotation Deleted");
        getQuotationList();
      } else {
        Notiflix.Report.failure(
          "Error",
          "Failed to delete quotation. " + responseJson[ApiKey._API_MESSAGE_KEY]
        );
      }
    });
  };

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const updateQuotationStatus = async (data) => {
    let dao = new QuotationDao();

    await dao.updateQuotationStatus(data, token).then((responseJson) => {
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        Notiflix.Report.Success("Success", "Quotation Status Updated");
        getQuotationList();
      } else {
        Notiflix.Report.failure(
          "Error",
          "Failed to update quotation status. " +
            responseJson[ApiKey._API_MESSAGE_KEY]
        );
      }
    });
  };

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  useEffect(() => {
    getQuotationList();
  }, []);

  const _COLUMN = useMemo(() => [
    {
      Header: "Quotation ID",
      Cell: ({ row }) => <b>{row.original.quotationId}</b>,
    },
    {
      Header: "Site Project Title",
      accessor: "enquiry.title",
    },
    {
      Header: "Enquiry Id",
      accessor: "enquiry.enquiryId",
    },

    {
      Header: "Status",
      Cell: ({ row }) => {
        let status = row.original.status;
        let classes = classnames("label custom-status-label", {
          "label-secondary": status === 0,
          "label-danger": status === -1,
          "label-success": status === 1,
          "label-danger": status === -2,
        });

        return <div className={classes}>{row.original.statusName}</div>;
      },
    },
    {
      Header: "Creation Date",
      accessor: (d) => {
        return moment(d.creation_date).local().format("DD-MM-YYYY hh:mm:ss A");
      },
    },
    {
      Header: "Created By",
      accessor: "created_by.username",
    },
    {
      Header: "Modified Date",
      accessor: (d) => {
        return moment(d.modified_date).local().format("DD-MM-YYYY hh:mm:ss A");
      },
    },
    {
      Header: "Modified By",
      accessor: "last_modified_by.username",
    },
    {
      Header: "Deleted Date",
      accessor: (d) => {
        if (d.deleted_date !== null) {
          return moment(d.deleted_date).local().format("DD-MM-YYYY hh:mm:ss A");
        } else {
          return "";
        }
      },
    },
    {
      Header: "Deleted By",
      accessor: "deleted_by.username",
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <>
          <div
            className="btn-table"
            title="View Quotation"
            onClick={() =>
              history.push(
                WebUrl._QUOTATION_CREATE_OR_UPDATE.replace(
                  ":id",
                  encodeURIComponent(row.original.quotationId)
                ),
                { quotation: row.original }
              )
            }
          >
            <i className="fa fa-eye"></i>
          </div>
          <div
            className="btn-table last-btn"
            title="Delete Quotation"
            onClick={() => {
              Notiflix.Confirm.show(
                "Confirmation",
                "Do you sure to delete quotation?",
                "Yes",
                "No",
                function () {
                  deleteQuotation(row.original);
                }
              );
            }}
          >
            <i className="fa fa-trash"></i>
          </div>
          {/* {row.original.status === 0 && (
            <WinLossButtons
              id={row.original.quotationId}
              type="Quotation"
              apiCall={updateQuotationStatus}
            />
          )} */}
        </>
      ),
      disableSortBy: true,
      disableFilters: true,
      style: { overflow: "hidden" },
      sticky: "right",
      width: 80,
    },
  ]);

  return (
    <>
      <div className=" float-xl-right">
        <button
          type="type"
          className="btn btn-sm btn-themed mb-2"
          onClick={() =>
            history.push(
              WebUrl._QUOTATION_CREATE_OR_UPDATE.replace(":id", "create")
            )
          }
        >
          <i className="fa fa-plus mr-1"></i>Add Quotation
        </button>
      </div>
      <h1 className="page-header">Quotation Management</h1>
      <div id="quotationsTable">
        <ReactTable
          columns={_COLUMN}
          data={quotationList && quotationList}
          getRowProps={(row) => {
            return {
              onDoubleClick: () =>
                history.push(
                  WebUrl._QUOTATION_CREATE_OR_UPDATE.replace(
                    ":id",
                    encodeURIComponent(row.original.quotationId)
                  ),
                  { quotation: row.original }
                ),
              onClick: (e) => {
                setSelectedRow(row.id);
              },
              class: row.id === selectedRow && "table-row-selected",
            };
          }}
          filterable
          canPreviousPage={true}
          canNextPage={true}
          ref={_tableRef}
        />
      </div>
    </>
  );
};
